const selectableTypes = [
    "rect", "text"
];

export  const setSelect = (obj, svgCANVAS, types = []) => {

    const playground = document.querySelector("#svg-playground");

    if(!playground.classList.contains("selection")){
        return false;
    }

    const editableItem = svgCANVAS.findOne(".editable");

    types.every( (type) => {

        if(type === "editable"){

            if(obj.item.hasClass("selected")){
                return false;
            }

            if( editableItem ){
                if(editableItem.parent() === obj.group){
                    return unsetSelect(editableItem.parent(),  ["editable"])
                } else {
                    unsetSelect(editableItem.parent(), ["editable"])
                }
            }

        }

        if(type === "selected"){

            if(obj.item.hasClass("editable")){
                return false;
            }

            if(obj.group.find(".selected").length){
                return !unsetSelect(obj.group, ["selected"]);
            }

        }

        obj.group.find( selectableTypes ).forEach(
            ( item ) => {
                item.addClass(type)
            }
        )

    })

}

export const unsetSelect = (obj, types = []) => {

    types.forEach( (type) => {

        const allItems = obj.find(`.${ type }`);

        if(allItems.length){
            allItems.forEach(
                ( item ) => {
                    item.removeClass(type)
                }
            )
        }

    })

    return true;

}
