import React from "react";
import { useQuery } from "@apollo/client";

import { GET_APP_PARAMS } from "store/query/local-store-gql";

import Sidebars from "components/sidebars";
import SvgPlayground from "components/playground";
import { useSvgInstance  } from "components/use-hooks";


const App = () => {

    const { data : { appParams } } = useQuery( GET_APP_PARAMS );

    useSvgInstance();

    return(
        <div className={ `app ${ appParams.appBlockClass.join(" ") }` } >
            <Sidebars.Tools />
            <SvgPlayground
                appParams={ appParams }
            />
        </div>

    );
}

export default App;