import React, { useState } from "react";
import { Button, Upload } from "antd";

import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


const PreUploadImage = ({
        extraClass = "",
        accept = 'image/*',
        children,
        disabled,
        onDelete = () => {},
        noData,
        photo = undefined,
        photoName = undefined,
        extraAction,
        ...props
    }) => {

    const [ imageData, setImageData ] = useState({
        url : photo,
        name : photoName
    });


    const uploadProps = {
        ...props,
        disabled,
        accept: accept,
        customRequest: () => {
            return false;
        },
        showUploadList: false,
        multiple: false,
        onChange : (e) => {

            getBase64(e.file.originFileObj, (url) => {

                setImageData({
                    url,
                    name : e.file.name
                });

                props.onChange({
                    file : url,
                    name : e.file.name
                });
            });

        }
    };

    return(
        <div className="pre-upload-image">
            { !imageData.url && noData &&
                <>{ noData }</>
            }
            { imageData.name &&
                <p className="img-name">
                    <span>{ imageData.name }</span>
                </p>
            }
            <div className="actions">
                <div className="group">
                    <Upload {...uploadProps}>
                        { !imageData.url && <Button icon={<UploadOutlined />}>Upload</Button> }
                    </Upload>
                    { !!imageData.url &&
                        <Button
                            className="icon"
                            onClick = { () => {
                                onDelete(
                                    () => {
                                        setImageData({
                                            url : undefined,
                                            name : undefined
                                        })
                                        props.onChange(undefined)
                                    }
                                );
                            } }
                        >
                            <DeleteOutlined />
                        </Button>
                    }
                </div>
                { (extraAction && imageUrl) && extraAction }
            </div>
        </div>
    );
};


export default PreUploadImage;