import React, { useEffect } from "react";
import { Form, Input, Button, Slider } from "antd";

import { useSvgInstance, useVarParam } from "components/use-hooks";
import { svgDraw } from "utils";


const SvgText = () => {

    const [ form ] = Form.useForm();

    const varParam = useVarParam(),
        { settings } = varParam.get(),
        svgCANVAS = useSvgInstance();

    useEffect( () => {
        form.resetFields(Object.keys(settings));
    }, [settings]);

    return(
        <Form
            layout="vertical"
            className= "tool text"
            form={ form }
            onFinish={ (values) => {

                svgDraw.text( svgCANVAS, {
                    ...settings,...values
                });

                return false;
            } }
        >
            <Form.Item
                label={ "Text" }
                name="text"
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="text" />
            </Form.Item>
            <Form.Item
                label={ "Font size" }
                name="fontSize"
                initialValue={ settings.fontSize }
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="fontSize" />
            </Form.Item>
            <Form.Item
                label={ "Text color" }
                name="textColor"
                initialValue={ settings.textColor }
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="textColor" />
            </Form.Item>
            <Form.Item
                label={ "Angle" }
                name="angle"
                initialValue={ 0 }
            >
                <Slider
                    marks={{
                        "-90" : "-90",
                        "-45" : "-45",
                        "0" : "0",
                        "45" : "45",
                        "90" : "90",
                    }}
                    min={ -90 }
                    max={ 90  }
                    included={ false }

                />
            </Form.Item>
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Create
                </Button>
            </div>
        </Form>
    )

}

export default SvgText;