import { helpers } from "utils";

const duplicate = ({ canvas }) => {
    if(canvas.findOne(".editable")){
        const item = canvas.findOne(".editable").parent();

        const newItem = item.clone().dy(item.height() + 1);

        helpers.events.setMainEvents(
            {
                obj: {
                    group: newItem,
                    item : newItem.children()[0]
                },
                svgCANVAS : canvas
            }
        )

        canvas.add(newItem);
    }


    return false;
}

const attachInOne = ({ canvas }) => {

    const group = canvas.findOne(".editable"),
          allSelected = canvas.find(".selected");

    if(group && allSelected.length){
        allSelected.forEach( ( item ) => {

            item.removeClass( "selected" );

            if(item.parent().hasClass("wrap")){
                item.parent()
                    .ungroup();
            }

            group.parent().put(item);
        })

        group.parent().find(".editable").forEach(
            ( item ) => item.removeClass("editable")
        );

    }

}

const putInBlock = ({
        canvas,
        blockName,
        onUngroup
    }) => {

    const groupHolder = canvas.group()
        .addClass( `block`)
        .data("block-name", blockName);

    const allSelected = canvas.find(".selected");

    if( allSelected.length ){

        allSelected.forEach( (item) => {

            item.removeClass("selected")
                .parent()
                .draggable(false)

            groupHolder.put( item.parent() );
        });


        //helpers.events.onBlockClick( groupHolder );
       //


        groupHolder.on('click', ({ altKey }) => {

            if(altKey){

                onUngroup(() => {

                    groupHolder.children().forEach((item) => {

                        if(item.hasClass("wrap")){
                            item.draggable(true);
                        }
                    })

                    groupHolder.ungroup();

                })

            }
        });
        helpers.events.onItemDrag( groupHolder );

    }
}


const actionWithSelection = (
    canvas,
    {
        blockName,
        selectionType,
        onUngroup = () => {}
    }) => {

    switch(selectionType){
        case "attach" :

            attachInOne({ canvas });

            break;

        case "block" :
            putInBlock({
                canvas,
                blockName,
                onUngroup
            });

            break;

        case "duplicate" :
                duplicate({ canvas });
            break;
    }

    helpers.unSelect(
        canvas,
        ["editable", "selected"]
    )

}

export default actionWithSelection;