import { InMemoryCache, makeVar } from "@apollo/client";
import packageInfo from '../../package.json'


const version = packageInfo.version;

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read () {
                        return !!localStorage.getItem("authToken");
                    }
                },
                appParams: {
                    read () {
                        return appParamsVar();
                    }
                }
            },
        },
    },
});

export const appParamsInitial = {
    headerClass           : [],
    mainBlockClass        : [],
    appBlockClass         : [],
    playgroundBlockClass  : [],
    headerTitle     : "",
    notifications      : {
        errorOn   : true,
        successOn : false
    },
    settings   : {
        tool : "settings",
        scale : 1,
        strokeWidth : 1,
        fontSize    : 12,
        opacity     : 100,
        textColor   : "#de413d"
    },
    SVG  : undefined,
    appVersion : version,
};

export const appParamsVar = makeVar(appParamsInitial);

export default cache;