import React from "react";

const SvgPlayground = ({ appParams }) => {

    return  (
        <div
            id="svg-playground"
            className={ appParams.settings.tool }
        />
    );
}


export default SvgPlayground;