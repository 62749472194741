import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useSvgInstance, useVarParam } from "components/use-hooks";
import { svgDraw } from "utils";



const { Option } = Select;

const SvgSelection = () => {

    const [ form ] = Form.useForm(),
          [ modal, contextHolder ] = Modal.useModal(),
          [ type, setType ]= useState("duplicate");

    const varParam = useVarParam(),
        { settings } = varParam.get(),
        svgCANVAS = useSvgInstance();

    useEffect( () => {
        form.resetFields(Object.keys(settings));
    }, [settings]);

    return(
        <Form
            layout="vertical"
            className= "tool selection"
            form={ form }
            onFinish={ ({ blockName, selectionType }) => {

                if(!svgCANVAS.find(".selected, .editable").length){
                    return false;
                }

                svgDraw.selection(svgCANVAS, {
                    blockName,
                    selectionType,
                    onUngroup : (callback) => {

                        modal.confirm({
                            title: 'Ungroup block',
                            icon: <ExclamationCircleOutlined />,
                            centered: true,
                            content: `A you sure that you want ungroup block ${ blockName }?`,
                            okText: 'Confirm',
                            cancelText: 'Cansel',
                            onOk: callback
                        });
                    }
                })

                return false;
            } }
        >
            <Form.Item
                label={ "Action type" }
                initialValue={ type }
                name="selectionType"
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >

                <Select
                    placeholder="Select a option and change input text above"
                    onChange={ (value) => {
                        setType(value);
                    }}
                >
                    <Option value="edit">Edit</Option>
                    <Option value="duplicate">Duplicate</Option>
                    <Option value="block">Block</Option>
                    <Option value="attach">Attach</Option>
                </Select>
            </Form.Item>
            { type === "block" &&
                <Form.Item
                    label={ "Block name" }
                    name="blockName"
                    rules={ [
                        {
                            required: true,
                        }
                    ] }
                >
                    <Input name="blockName" />
                </Form.Item>
            }
            { contextHolder }
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Confirm
                </Button>
            </div>
        </Form>
    )

}

export default SvgSelection;