import { helpers } from "utils";

const makeText = (
    canvas,
    {
        text,
        textColor,
        fontSize,
        angle,
        opacity = 100
    }) => {

    const lastItem = canvas.last();

    const itemGroup = canvas.group()
                            .addClass("wrap text"),
          textItem = itemGroup.text(text)
                              .fill(textColor);

    textItem.font({
        size: fontSize
    }).opacity(opacity / 100)
      .addClass( `plain-text`);

    if(lastItem && lastItem.type !== "image" ){

        textItem.move(
            lastItem.x(),
            lastItem.y() + lastItem.height() + 5
        );
    }

    if(angle){
        textItem.rotate(angle);
    }

    helpers.events.setMainEvents({
        obj : {
            group : itemGroup,
            item  : textItem
        },
        svgCANVAS : canvas
    });

}

export default makeText;