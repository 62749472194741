import React from "react";
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from "antd";

import SvgTools from "components/tools";
import { useVarParam } from "components/use-hooks";


const { Panel } = Collapse;

const ToolsSidebar = () => {

    const varParam = useVarParam(),
        { settings } = varParam.get();

    return(
        <div className="tools-sidebar">
            <Collapse
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                activeKey={ [ settings.tool ] }
                accordion = { true }
                bordered={ false }
                onChange={ ([tool]) => {
                    varParam.set({
                        settings: {...settings, tool }
                    })
                } }
            >
                <Panel header="Select" key="selection">
                    <SvgTools.Selection/>
                </Panel>
                <Panel header="Create rectangle" key="rectangle">
                    <SvgTools.Rect/>
                </Panel>
                <Panel header="Create text" key="text">
                    <SvgTools.Text/>
                </Panel>
                <Panel header="Settings" key="settings">
                    <SvgTools.Settings/>
                </Panel>
            </Collapse>
            <div className="tools-sidebar-actions">
                <SvgTools.DownloadBtn />
            </div>
        </div>
    )

}

export default ToolsSidebar;