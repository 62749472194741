import { helpers } from "utils";

const makeRect = (canvas, {
        width,
        height,
        strokeWidth,
        opacity = 100,
        radius,
        angle,
        scale,
        wrapType = "stand"
    }) => {

    const lastItem = canvas.last();

    const itemGroup = canvas.group(),
          rect = itemGroup.rect(
            Number(width) * scale * 10,
            Number(height) * scale  * 10
          ).attr({
            fill: '#f8f8f8',
            stroke: '#000',
            'stroke-width': strokeWidth
        });

    itemGroup.y(10)
             .opacity(opacity / 100)
             .data({ type : wrapType })
             .addClass(`wrap ${ wrapType }`);


    if(lastItem && lastItem.type !== "image" ){

        itemGroup.move(
            lastItem.x() + lastItem.width() + strokeWidth,
            lastItem.y()
        );
    }

   if(angle){
       rect.rotate(angle);
   }

   if(radius){
       rect.radius(radius);
   }

    helpers.events.setMainEvents({
        obj : {
            group : itemGroup,
            item  : rect
        },
        svgCANVAS : canvas
    });

}

export default makeRect;