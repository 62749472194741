import React from "react";
import { Button } from "antd";

import { useSvgInstance } from "components/use-hooks";
import { helpers } from "utils";


const DownloadBtn = () => {
    const svgCANVAS = useSvgInstance();



    return(
        <Button type="primary" onClick={ () => {

            const img = svgCANVAS.find(".bg"),
                  originalViewBox = svgCANVAS.viewbox();

            if(img){
                svgCANVAS.viewbox(`0 0 ${ img.attr()[0].width } ${ img.attr()[0].height }`)
            }

            helpers.download(
                document.querySelector("#svg-playground").innerHTML
            )

            if(img) {
                svgCANVAS.viewbox(originalViewBox);
            }
        } }>Download SVG</Button>
    )
}

export default DownloadBtn;