import React from "react";
import {Form, Input, Button, Slider} from "antd";

import { PreUploadImage } from "components/service";
import { useSvgInstance, useVarParam } from "components/use-hooks";


const SvgSettings = () => {

    const [ form ] = Form.useForm();

    const varParam = useVarParam(),
          { settings } = varParam.get(),
          svgCANVAS = useSvgInstance();

    return(
        <Form
            layout="vertical"
            className= "tool settings"
            form={ form }
            onFinish={ ({ file, ...values }) => {

                varParam.set(
                    {
                        settings: {
                            ...settings,
                            ...Object.entries(values)
                                .reduce( ( params, [ key, item ]) => {

                                    return {
                                        ...params,
                                        [key] : Number(item)
                                    };
                                }, {})
                        }
                    }
                )

                svgCANVAS.find(".wrap").forEach(
                    ( item ) => item.opacity( values.opacity / 100 )
                )

                svgCANVAS.find(".bg").remove();

                if(file){
                    let img = svgCANVAS.image(file)
                        .addClass( `bg`)
                        .back();

                    img.on('load', () => {
                        const { width, height } = img.attr();
                        //      ratio = width / window.innerWidth;
                        /*
                        img.attr({
                            width: width / ratio,
                            height : height / ratio
                        });
                        */
                        svgCANVAS.viewbox(`0 0 ${ width } ${ height }`)
                    });

                }

                return false;
            } }
        >
            <Form.Item
                label={ "Scale" }
                name="scale"
                initialValue={ settings.scale }
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="scale" />
            </Form.Item>
            <Form.Item
                label={ "Stroke width" }
                name="strokeWidth"
                initialValue={ settings.strokeWidth }
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="strokeWidth" />
            </Form.Item>

            <Form.Item
                label={ "Font size" }
                name="fontSize"
                initialValue={ settings.fontSize  }
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >
                <Input name="fontSize" />
            </Form.Item>
            <Form.Item
                label={ "Items opacity" }
                name="opacity"
                initialValue={ settings.opacity }
            >
                <Slider
                    marks={{
                        "0"   : "0",
                        "25"  : "25",
                        "50"  : "50",
                        "75"  : "75",
                        "100" : "100",
                    }}
                    min={ 0 }
                    max={ 100  }
                    included={ false }

                />
            </Form.Item>
            <Form.Item
                name="file"
                valuePropName="file"
                getValueFromEvent={ (e) => {

                    if (Array.isArray(e)) {
                        return e;
                    }

                    return e?.file;
                }}
            >
                <PreUploadImage
                    onDelete={ (callback) => {
                        svgCANVAS.find(".bg").remove();
                        callback();
                    }}
                />
            </Form.Item>
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </div>
        </Form>
    )

}

export default SvgSettings;