import { useEffect } from "react";
import * as SVG from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import '@svgdotjs/svg.panzoom.js';

import { useVarParam } from "components/use-hooks/index";

let svgCanvas = SVG.makeInstance();

const useSvgInstance = () => {

    const varParam = useVarParam();

    useEffect( () => {

        if(!varParam.get("SVG")){
            svgCanvas.addTo('#svg-playground')
                     .size("100%", "100%")
                     .viewbox('0 0 1000 1000')
                     .panZoom({
                        zoomMin: 0.1,
                        zoomMax: 10,
                        zoomFactor: 0.05
                     });

            varParam.set({
                "SVG" : SVG,
            });
        }
    }, []);

    return svgCanvas;
}

export default useSvgInstance;