import SvgRectangle from "./rectangle";
import SvgSettings from "./settings";
import SvgText from "./text";
import SvgSelection from "./selection";
import DownloadBtn from "./download";

import "./tools.scss";


const SvgTools = {
    Settings : SvgSettings,
    Rect : SvgRectangle,
    Text : SvgText,
    Selection : SvgSelection,
    DownloadBtn : DownloadBtn
};

export default SvgTools;