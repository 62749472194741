import { setSelect, unsetSelect } from "./select-helper";
import downloadSVG from "./svg-download-helper";
import events from "./events-helper";


const helpers = {
    select   : setSelect,
    unSelect : unsetSelect,
    download : downloadSVG,
    events   : events
}

export default helpers;