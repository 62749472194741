import { helpers } from "utils";

const setMainEvents = ({
        obj,
        svgCANVAS
    }) => {

    onItemSelectClick({
        obj, svgCANVAS, types : [ "selected" ]
    });

    onItemEditClick({
        obj, svgCANVAS,  types : [ "editable" ]
    });

    onItemDrag(obj.group);
}

const onItemSelectClick = ({ obj, svgCANVAS, types }) => {

    const { group } = obj;

    group.on('click', ({ altKey, ctrlKey, shiftKey }) => {

        if(!group.parent().hasClass("block") && !shiftKey){
            if(altKey && !group.find(".selected").length){
                group.remove();
            }

            if(!ctrlKey){
                helpers.select(obj, svgCANVAS, types)
            }

        }
    });

}

const onItemEditClick = ({ obj, svgCANVAS, types }) => {

    const { group } = obj;

    group.on('click', ({ shiftKey }) => {

        if(shiftKey){
            helpers.select(obj, svgCANVAS, types)
        }
    });

}

const onBlockClick = (group) => {

    group.on('click', ({ altKey }) => {

        if(altKey){

            group(() => {

                group.children().forEach((item) => {

                    if(item.hasClass("wrap")){
                        item.draggable(true);
                    }
                })

                group.ungroup();

            })

        }
    });
}

const onItemDrag = (item) => {

    item.draggable().on('dragmove', (e) => {

        const { detail : {
            event
        } } = e;

        if( !event.ctrlKey){
            e.preventDefault();
        }
    });

}

const events = {
    setMainEvents,
    onBlockClick,
    onItemSelectClick,
    onItemEditClick,
    onItemDrag,
}

export default events;