const downloadBlob = (blob, filename) => {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);

}

const downloadSVG = (svg) => {
    const blob = new Blob([svg], { type: "image/svg+xml" });
    downloadBlob(blob, `hall-plan.svg`);
}

export default downloadSVG;