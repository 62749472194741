import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from "@apollo/client";

import client from "store/client";

import App from 'components/app';

import "styles/main.scss";


createRoot( document.querySelector('#root') )
    .render(
        <ApolloProvider client={ client }>
            <App />
        </ApolloProvider>
    );