import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Slider } from "antd";

import { svgDraw, helpers } from "utils";
import { useVarParam, useSvgInstance } from "components/use-hooks";


const { Option } = Select;

const SvgRectangle = () => {

    const [ form ] = Form.useForm(),
          [ type, setType ] = useState("stand");

    const varParam = useVarParam(),
          { settings } = varParam.get(),
          svgCANVAS = useSvgInstance();

    useEffect( () => {
        form.resetFields(Object.keys(settings));
    }, [settings]);

    return(
        <Form
            layout="vertical"
            className= "tool rect"
            form={ form }
            onFinish={ (values) => {

                svgDraw.rect( svgCANVAS, {
                    ...settings,
                    ...values
                } );

                return false;
            } }
        >
            <Form.Item
                label={ "Type" }
                initialValue={ type }
                name="wrapType"
                rules={ [
                    {
                        required: true,
                    }
                ] }
            >

                <Select
                    placeholder="Select a option and change input text above"
                    onChange={ (value) => {
                        setType(value);
                    }}
                >
                    <Option value="stand">Stand</Option>
                    <Option value="figure">Figure</Option>
                </Select>
            </Form.Item>
            <div className="row">
                <Form.Item
                    label={ "Width" }
                    name="width"
                    initialValue={ 1 }
                    rules={ [
                        {
                            required: true,
                        }
                    ] }
                >
                    <Input name="width" />
                </Form.Item>
                <Form.Item
                    label        = { "Height" }
                    name         = "height"
                    initialValue = { 1 }
                    rules={ [
                        {
                            required: true,
                        }
                    ] }
                >
                    <Input name="height" />
                </Form.Item>
            </div>
            <div className="row">
                <Form.Item
                    label        = { "Stroke width" }
                    name         = "strokeWidth"
                    shouldUpdate = { true }
                    initialValue = { settings.strokeWidth }
                    rules={ [
                        {
                            required: true,
                        }
                    ] }
                >
                    <Input name="strokeWidth" />
                </Form.Item>
                <Form.Item
                    label={ "Radius" }
                    name="radius"
                    initialValue={ 0 }
                >
                    <Input name="radius" />
                </Form.Item>
            </div>

            <Form.Item
                label={ "Angle" }
                name="angle"
                initialValue={ 0 }
            >
                <Slider
                    marks={{
                        "-90" : "-90",
                        "-45" : "-45",
                        "0" : "0",
                        "45" : "45",
                        "90" : "90",
                    }}
                    min={ -90 }
                    max={ 90  }
                    included={ false }

                />
            </Form.Item>
            <div className="form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Create
                </Button>
            </div>
        </Form>
    );

}

export default SvgRectangle;