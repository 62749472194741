import makeRect from "./draw-rect";
import makeText from "./draw-text";
import makeSelection from "./draw-selection";


const svgDraw = {
    rect  : makeRect,
    text  : makeText,
    selection : makeSelection
}

export default svgDraw;